.cheese {
  width: 100%;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  .cheese__cheese-title {
    font-size: 18px;
    margin: 30px 0;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
