.checkout-page {
  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;

    &__left-column,
    &__right-column {
      flex: 1;
      margin: 40px 0;
      padding: 0 5px;
      min-width: 300px;
    }

    &__left-column {
      &__form-wrapper {
        box-sizing: border-box;
        @media (min-width: 400px) {
          padding: 10px 30px;
        }

        @media (max-width: 400px) {
          padding: 10px;
        }
      }
    }

    &__right-column {
      &__row {
        border-bottom: 1px solid #c3c3c3;

        &:last-child {
          border-bottom: 0;
        }

        &__row-item {
          margin: 0;
          padding: 4px 0;
        }
      }

      &__meals {
        margin-top: 10px;
      }
    }
  }
}
