@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cairo&display=swap);
.arabic-text h1,
.arabic-text h2,
.arabic-text h3,
.arabic-text h4,
.arabic-text p,
.arabic-text label {
  direction: rtl;
  font-family: 'Cairo', sans-serif; }

.app-header-container {
  background-color: white;
  position: fixed;
  z-index: 10;
  width: 100%;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid; }
  @media (max-width: 400px) {
    .app-header-container {
      padding-left: 10px;
      padding-right: 10px; } }
  .app-header-container__content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .app-header-container__content__side-content {
      display: flex;
      align-items: center; }
      .app-header-container__content__side-content__languages-wrapper {
        display: flex;
        flex-direction: row; }
        .app-header-container__content__side-content__languages-wrapper p {
          margin: 0;
          cursor: pointer;
          margin-left: 10px; }
          .app-header-container__content__side-content__languages-wrapper p:hover {
            opacity: 0.8; }
    @media (max-width: 500px) {
      .app-header-container__content .app-logo {
        width: 80px; } }

.sign-in-form {
  min-width: 280px; }
  .sign-in-form__inputs-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    .sign-in-form__inputs-row__input {
      flex: 1 1;
      min-width: 180px;
      padding-right: 10px; }
      .sign-in-form__inputs-row__input:last-child {
        padding-right: 0; }
  .sign-in-form__note {
    margin-top: 40px;
    width: 100%;
    text-align: center; }

.home-page {
  position: relative; }
  .home-page__image-container {
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center; }
    @media (min-width: 900px) {
      .home-page__image-container {
        padding: 100px 0;
        background-position: left bottom 78%; } }
    @media (max-width: 900px) {
      .home-page__image-container {
        padding: 200px 0 10px 0; } }
    .home-page__image-container .overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5); }
    .home-page__image-container.primary-image {
      background-image: url("https://res.cloudinary.com/freshly/image/upload/c_fill,f_auto,dpr_auto,q_30/v1494958610/homepage_steakpepp_hero_tablet"); }
    .home-page__image-container.secondary-image {
      background-image: url(/static/media/home-secondary-background.ac38772d.jpg); }
      .home-page__image-container.secondary-image h1 {
        z-index: 2;
        color: white; }
    .home-page__image-container.third-image {
      background-image: url(/static/media/home-primary-background.7f97f6e0.jpg); }
  .home-page__heading-container {
    padding: 50px 0; }
  @media (min-width: 900px) {
    .home-page__push-right {
      margin-left: 50%; } }
  .home-page__form-wrapper {
    background-color: white;
    box-sizing: border-box;
    max-width: 460px;
    padding: 20px; }
    @media (max-width: 900px) {
      .home-page__form-wrapper {
        margin: auto; } }
    .home-page__form-wrapper.center {
      margin: auto; }
    .home-page__form-wrapper__description-container {
      padding: 10px 0 20px 0;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .home-page__form-wrapper__description-container p {
        font-size: 20px;
        text-align: center; }
  .home-page__how-it-works__item-content {
    margin-top: 40px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
    @media (min-width: 1000px) {
      .home-page__how-it-works__item-content__line {
        position: absolute;
        top: 70px;
        left: 10%;
        right: 10%;
        height: 1px;
        z-index: -1;
        background-image: -webkit-gradient(linear, left top, right top, color-stop(70%, transparent), color-stop(20%, #3b7));
        background-image: -webkit-linear-gradient(left, transparent 70%, #3b7 20%);
        background-image: linear-gradient(to right, transparent 70%, #3b7 20%);
        background-size: 8px 100%; } }
    @media (max-width: 1000px) {
      .home-page__how-it-works__item-content__line {
        display: none; } }
    .home-page__how-it-works__item-content__item {
      box-sizing: border-box;
      padding: 0 20px;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start; }
      .home-page__how-it-works__item-content__item img {
        width: 140px;
        height: 140px;
        object-fit: contain; }
      .home-page__how-it-works__item-content__item h4,
      .home-page__how-it-works__item-content__item p {
        text-align: center; }
      .home-page__how-it-works__item-content__item h4 {
        font-size: 18px;
        margin-top: 20px; }
      .home-page__how-it-works__item-content__item p {
        font-size: 16px; }
  .home-page__meal-advantages {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .home-page__meal-advantages__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; }
      .home-page__meal-advantages__container__item {
        display: flex;
        z-index: 2;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 30px 0;
        min-width: 100px;
        max-width: 150px;
        padding: 0 20px; }
        .home-page__meal-advantages__container__item img {
          width: 100px;
          height: 100px;
          object-fit: contain; }
        .home-page__meal-advantages__container__item h3 {
          color: white;
          text-align: center;
          text-transform: uppercase;
          margin-top: 30px;
          font-size: 18px; }
  .home-page__reviews-container {
    background-color: #f7f7f7; }
    .home-page__reviews-container__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      padding: 0 20px; }
      .home-page__reviews-container__item__star-icon {
        color: #f5d000;
        font-size: 22px;
        margin: 0 5px; }
      .home-page__reviews-container__item__header, .home-page__reviews-container__item__content, .home-page__reviews-container__item__author {
        text-align: center; }
      .home-page__reviews-container__item__header {
        font-size: 22px;
        margin-top: 30px; }
      .home-page__reviews-container__item__content {
        font-size: 18px;
        padding: 10px 0; }
      .home-page__reviews-container__item__author {
        font-size: 16px;
        font-weight: bold; }
  .home-page__footer {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; }
    .home-page__footer h4 {
      font-size: 16px; }
    .home-page__footer__column {
      flex: 1 1;
      min-width: 120px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .home-page__footer__column p {
        margin-top: 10px; }
      .home-page__footer__column__list {
        list-style: none;
        padding: 0; }
        .home-page__footer__column__list li {
          cursor: pointer; }
          .home-page__footer__column__list li:hover {
            opacity: 0.7; }

.cart-item {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (max-width: 150px) {
    .cart-item__content {
      display: none; }
    .cart-item__controls-container {
      display: none; } }
  .cart-item img {
    width: 60px;
    height: 60px;
    object-fit: cover; }
  .cart-item__content {
    box-sizing: border-box;
    padding: 10px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .cart-item__content .app-header,
    .cart-item__content .app-item-description {
      text-align: left; }
  .cart-item__controls-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px; }
    .cart-item__controls-container__add-item, .cart-item__controls-container__remove-item {
      font-size: 18px;
      margin: 7px 0;
      cursor: pointer; }
    .cart-item__controls-container__add-item {
      color: green; }
    .cart-item__controls-container__remove-item {
      color: red; }
    .cart-item__controls-container p {
      margin: 0; }

.cheese {
  width: 100%;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid; }
  .cheese .cheese__cheese-title {
    font-size: 18px;
    margin: 30px 0;
    font-weight: bold;
    cursor: pointer; }
    .cheese .cheese__cheese-title:hover {
      text-decoration: underline; }

.common-questions {
  box-sizing: border-box;
  padding: 0 20px; }
  .common-questions h1 {
    width: 100%;
    text-align: center; }
  .common-questions .common-questions__item__answer {
    font-size: 16px; }


.fill-user-info-form__input {
  padding-right: 10px; }
  .fill-user-info-form__input:last-child {
    padding-right: 0; }

.fill-user-info-form__submit-button {
  margin-top: 10px; }

.select-payment-method-form__submit-button {
  margin-top: 10px; }

.tile {
  border: 1px solid #dbdbdb; }
  .tile h3 {
    background-color: #f7f7f7;
    padding: 10px 10px;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
    margin: 0; }
  .tile__content-wrapper {
    padding: 10px; }

.checkout-page__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse; }
  .checkout-page__content__left-column, .checkout-page__content__right-column {
    flex: 1 1;
    margin: 40px 0;
    padding: 0 5px;
    min-width: 300px; }
  .checkout-page__content__left-column__form-wrapper {
    box-sizing: border-box; }
    @media (min-width: 400px) {
      .checkout-page__content__left-column__form-wrapper {
        padding: 10px 30px; } }
    @media (max-width: 400px) {
      .checkout-page__content__left-column__form-wrapper {
        padding: 10px; } }
  .checkout-page__content__right-column__row {
    border-bottom: 1px solid #c3c3c3; }
    .checkout-page__content__right-column__row:last-child {
      border-bottom: 0; }
    .checkout-page__content__right-column__row__row-item {
      margin: 0;
      padding: 4px 0; }
  .checkout-page__content__right-column__meals {
    margin-top: 10px; }

.choose-delivery-day-page {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .choose-delivery-day-page__days-section {
    padding: 0;
    margin-top: 10px; }
    .choose-delivery-day-page__days-section__item {
      position: relative;
      list-style: none;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 30px;
      width: 300px;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .choose-delivery-day-page__days-section__item__pointer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 6px;
        background-color: blue; }
      .choose-delivery-day-page__days-section__item p {
        margin: 0;
        padding: 0; }
      .choose-delivery-day-page__days-section__item:hover {
        -webkit-transform: translateX(6px);
                transform: translateX(6px);
        -webkit-transition: 0.3s;
        transition: 0.3s; }
  .choose-delivery-day-page__next-button {
    margin: 20px 0 40px 0;
    width: 220px; }

.plan-card {
  background-color: white;
  min-width: 200px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #bfbfbf;
  cursor: pointer; }
  @media (min-width: 500px) {
    .plan-card {
      max-width: 320px; } }
  @media (max-width: 500px) {
    .plan-card {
      display: flex;
      align-items: stretch; } }
  .plan-card__card-image {
    object-fit: cover; }
    @media (min-width: 500px) {
      .plan-card__card-image {
        width: 100%;
        height: 200px; } }
    @media (max-width: 500px) {
      .plan-card__card-image {
        width: 115px;
        height: 115px; } }
  .plan-card__card-body {
    width: 100%;
    box-sizing: border-box;
    padding: 15px; }
    @media (min-width: 500px) {
      .plan-card__card-body__small-header {
        display: none; } }
    @media (max-width: 500px) {
      .plan-card__card-body__big-header {
        display: none; } }
    .plan-card__card-body h1,
    .plan-card__card-body h2,
    .plan-card__card-body p {
      text-align: center; }
    .plan-card__card-body h1 {
      font-size: 42px; }
    .plan-card__card-body h2 {
      text-transform: uppercase;
      font-weight: 100;
      margin-top: 10px; }
    @media (max-width: 500px) {
      .plan-card__card-body {
        padding: 15px; }
        .plan-card__card-body__button {
          display: none; }
        .plan-card__card-body h1,
        .plan-card__card-body h2,
        .plan-card__card-body p {
          text-align: left; }
        .plan-card__card-body h1 {
          font-size: 16px;
          margin-bottom: 0; }
        .plan-card__card-body h2 {
          font-size: 16px;
          margin: 0; }
        .plan-card__card-body p {
          margin-bottom: 0; } }
    .plan-card__card-body__description-container {
      display: flex;
      flex-direction: row;
      justify-content: center; }
      @media (max-width: 500px) {
        .plan-card__card-body__description-container p {
          font-size: 12px; } }
  .plan-card:hover {
    box-shadow: 0px 0px 5px 0px #969696; }

.choose-plan-page__plans-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center; }
  .choose-plan-page__plans-row__item {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px; }

.meal-card {
  min-width: 140px;
  max-width: 320px; }
  .meal-card__image {
    width: 100%;
    background-size: cover;
    background-position: center; }
    .meal-card__image::after {
      content: '';
      padding-top: 100%;
      display: block; }
  .meal-card__body {
    padding: 10px; }
    @media (min-height: 500px) {
      .meal-card__body h4.app-header {
        font-size: 12px; }
      .meal-card__body p.app-item-description {
        font-size: 10px; } }
    .meal-card__body h3,
    .meal-card__body p {
      text-align: center; }
    .meal-card__body h3 {
      margin-top: 8px; }
    .meal-card__body__count-wrapper {
      height: 25px;
      border-radius: 40px;
      background-color: white;
      border: 1px #c4c4c4 solid;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .meal-card__body__count-wrapper p {
        margin: 0;
        padding: 0 30px; }
        @media (min-height: 500px) {
          .meal-card__body__count-wrapper p {
            padding: 0 10px; } }
    .meal-card__body__controls-container {
      margin-bottom: 7px; }

.meal-modal__description {
  margin-top: 20px; }

.meal-modal__content {
  display: flex;
  flex-direction: row;
  margin-top: 20px; }
  .meal-modal__content__item {
    flex: 1 1;
    border-right: 1px solid rgba(0, 0, 0, 0.2); }
    .meal-modal__content__item:last-child {
      border-right: 0; }
    .meal-modal__content__item h3 {
      font-weight: 700; }
    .meal-modal__content__item p {
      text-transform: uppercase;
      text-align: center; }

.select-meals-page {
  height: 100%; }
  .select-meals-page__layout {
    height: 100%;
    position: relative;
    background-color: #eeeeee; }
    .select-meals-page__layout__content {
      height: calc(100vh - 64px);
      background-color: #f7f7f7;
      display: flex;
      flex-direction: column; }
      .select-meals-page__layout__content__note {
        text-align: center;
        padding: 10px 10px 0 10px;
        margin: 0; }
      .select-meals-page__layout__content__container {
        flex: 1 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-content: flex-start;
        padding: 0 10px; }
        .select-meals-page__layout__content__container__item {
          cursor: pointer;
          padding: 5px;
          flex: 1 1; }
          @media (min-height: 500px) {
            .select-meals-page__layout__content__container__item {
              width: 50%; } }
      .select-meals-page__layout__content .select-meals-page__layout__content__button {
        margin-bottom: 30px;
        height: 60px; }
        @media (min-width: 500px) {
          .select-meals-page__layout__content .select-meals-page__layout__content__button {
            display: none; } }
    .select-meals-page__layout__collapse-button-container {
      box-sizing: border-box;
      z-index: 10;
      padding: 0 5px;
      height: 80px;
      background-color: white;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0; }
      .select-meals-page__layout__collapse-button-container__info {
        font-size: 14px; }
        @media (min-width: 400px) {
          .select-meals-page__layout__collapse-button-container__info {
            display: none; } }
    .select-meals-page__layout__side-bar {
      height: calc(100vh - 64px);
      background-color: #eeeeee; }
      @media (max-width: 500px) {
        .select-meals-page__layout__side-bar {
          margin-top: 15vh;
          height: calc(70vh); } }
      @media (max-width: 150px) {
        .select-meals-page__layout__side-bar h3.app-header {
          display: none; } }
      .select-meals-page__layout__side-bar__content {
        height: 100%;
        display: flex;
        flex-direction: column; }
        .select-meals-page__layout__side-bar__content__meals-container {
          flex: 1 1; }
        .select-meals-page__layout__side-bar__content__note {
          text-align: center;
          padding: 10px 10px 0 10px;
          margin: 0; }
        .select-meals-page__layout__side-bar__content__button-container {
          box-sizing: border-box;
          padding: 20px;
          background-color: #eeeeee; }
          .select-meals-page__layout__side-bar__content__button-container__button {
            margin: 0;
            padding: 0; }
            @media (max-width: 500px) {
              .select-meals-page__layout__side-bar__content__button-container__button {
                display: none; } }

.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .thank-you-page h3 {
    text-align: center;
    padding: 0 20px; }

.join-now-page__header-content {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (max-width: 600px) {
    .join-now-page__header-content.large {
      display: none; } }
  @media (min-width: 600px) {
    .join-now-page__header-content.small {
      display: none; } }
  .join-now-page__header-content__icon {
    margin: 0 10px;
    font-size: 8px; }
  .join-now-page__header-content__item.selected {
    color: blue; }
  .join-now-page__header-content p {
    margin: 0;
    padding: 0;
    cursor: pointer; }
    .join-now-page__header-content p:hover {
      opacity: 0.7; }


@charset "UTF-8";
.alice-carousel {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: auto; }

.alice-carousel__wrapper {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden; }

.alice-carousel__stage {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  box-sizing: border-box; }
  .alice-carousel__stage-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    line-height: 0; }
    .alice-carousel__stage-item * {
      line-height: normal;
      line-height: initial; }
    .alice-carousel__stage-item.__cloned {
      opacity: 0;
      visibility: hidden; }
  .alice-carousel__stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  width: 50%;
  padding: 15px 10px;
  box-sizing: border-box; }
  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    content: attr(data-area);
    position: relative;
    text-transform: capitalize; }

.alice-carousel__prev-btn {
  text-align: right; }

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  color: #465798;
  cursor: pointer; }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: darkred; }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: .4; }

.alice-carousel__play-btn {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 20px; }
  .alice-carousel__play-btn:hover {
    cursor: pointer; }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    background-color: #fff;
    padding: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%; }

.alice-carousel__play-btn-item {
  cursor: pointer;
  position: absolute;
  background: transparent;
  width: 32px;
  height: 32px;
  outline: none;
  border: 0; }
  .alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    border-style: solid;
    border-color: transparent;
    border-width: 8px 0 8px 15px;
    border-left-color: #465798;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
  .alice-carousel__play-btn-item::before {
    height: 14px;
    left: 5px; }
  .alice-carousel__play-btn-item::after {
    left: 18px;
    top: 7px; }
  .alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    border-width: 0 0 0 10px;
    height: 30px; }
  .alice-carousel__play-btn-item.__pause::after {
    left: 18px;
    top: 0; }

.alice-carousel__dots {
  margin: 30px 0 5px;
  text-align: center;
  list-style: none;
  padding: 0; }
  .alice-carousel__dots-item {
    display: inline-block;
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb; }
    .alice-carousel__dots-item:not(:last-child) {
      margin-right: 15px; }
    .alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
      background-color: #6e7ebc; }

.alice-carousel__slide-info {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6); }
  .alice-carousel__slide-info-item {
    line-height: 0;
    vertical-align: middle; }

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.alice-carousel .animated-out {
  z-index: 1; }

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p {
  font-family: 'Montserrat', sans-serif; }

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif; }

.app-content {
  width: 100%;
  max-width: 1280px;
  margin: auto; }

.app-logo {
  width: 100px;
  object-fit: contain; }

h1.app-section-header {
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 20px; }

p.app-section-description {
  text-align: center;
  padding: 8px 20px 20px 20px;
  font-size: 16px; }

h3.app-header {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 30px;
  padding: 0 10px; }

h4.app-header {
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 0;
  padding: 0 10px; }

p.app-item-description {
  margin: 0;
  font-size: 12px;
  text-align: center; }

.header-spacer {
  height: 64px;
  width: 100%; }

.bullet::after {
  content: '•';
  color: #030;
  margin: 0 10px; }

#root {
  height: 100%; }

