.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
    padding: 0 20px;
  }
}
