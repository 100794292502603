.join-now-page {
  &__header-content {
    @media (max-width: 600px) {
      &.large {
        display: none;
      }
    }
    @media (min-width: 600px) {
      &.small {
        display: none;
      }
    }

    display: flex;
    flex-direction: row;
    align-items: center;

    &__icon {
      margin: 0 10px;
      font-size: 8px;
    }

    &__item {
      &.selected {
        color: blue;
      }
    }

    p {
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}