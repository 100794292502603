.fill-user-info-form {
  &__input {
    padding-right: 10px;
    &:last-child {
      padding-right: 0;
    }
  }

  &__submit-button {
    margin-top: 10px;
  }
}
