.app-header-container {
  background-color: white;
  position: fixed;
  z-index: 10;
  width: 100%;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

  @media (max-width: 400px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__side-content {
      display: flex;
      align-items: center;

      &__languages-wrapper {
        display: flex;
        flex-direction: row;
        p {
          margin: 0;
          cursor: pointer;
          margin-left: 10px;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    @media (max-width: 500px) {
      .app-logo {
        width: 80px;
      }
    }
  }
}
