.meal-modal{
  &__description {
    margin-top: 20px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    &__item {
      flex: 1;
      border-right: 1px solid rgba(0, 0, 0, 0.2);

      &:last-child {
        border-right: 0;
      }

      h3 {
        font-weight: 700;
      }

      p {
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}
