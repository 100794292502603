.sign-in-form {
  min-width: 280px;

  &__inputs-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &__input {
      flex: 1;
      min-width: 180px;

      padding-right: 10px;
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__note {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
}
