.choose-plan-page {
  &__plans-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;

    &__item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
    }
  }
}
