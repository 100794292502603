@import '../../../variables.scss';

.home-page {
  position: relative;

  &__image-container {
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;

    @media (min-width: 900px) {
      padding: 100px 0;
      background-position: left bottom 78%;
    }

    @media (max-width: 900px) {
      padding: 200px 0 10px 0;
    }

    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &.primary-image {
      background-image: url('https://res.cloudinary.com/freshly/image/upload/c_fill,f_auto,dpr_auto,q_30/v1494958610/homepage_steakpepp_hero_tablet');
    }

    &.secondary-image {
      background-image: url('assets/images/home-secondary-background.jpg');

      h1 {
        z-index: 2;
        color: white;
      }
    }

    &.third-image {
      background-image: url('assets/images/home-primary-background.jpg');
    }
  }

  &__heading-container {
    padding: 50px 0;
  }

  &__push-right {
    @media (min-width: 900px) {
      margin-left: 50%;
    }
  }

  &__form-wrapper {
    background-color: white;
    box-sizing: border-box;
    max-width: 460px;
    padding: 20px;
    @media (max-width: 900px) {
      margin: auto;
    }

    &.center {
      margin: auto;
    }

    &__description-container {
      padding: 10px 0 20px 0;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;

      p {
        font-size: 20px;
        text-align: center;
      }
    }
  }

  &__how-it-works {
    &__item-content {
      margin-top: 40px;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &__line {
        @media (min-width: 1000px) {
          position: absolute;
          top: 70px;
          left: 10%;
          right: 10%;
          height: 1px;
          z-index: -1;
          background-image: linear-gradient(to right, transparent 70%, #3b7 20%);
          background-size: 8px 100%;
        }

        @media (max-width: 1000px) {
          display: none;
        }
      }

      &__item {
        box-sizing: border-box;
        padding: 0 20px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        img {
          width: 140px;
          height: 140px;
          object-fit: contain;
        }

        h4,
        p {
          text-align: center;
        }

        h4 {
          font-size: 18px;
          margin-top: 20px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  &__meal-advantages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &__item {
        display: flex;
        z-index: 2;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 30px 0;
        min-width: 100px;
        max-width: 150px;
        padding: 0 20px;

        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }

        h3 {
          color: white;
          text-align: center;
          text-transform: uppercase;
          margin-top: 30px;
          font-size: 18px;
        }
      }
    }
  }

  &__reviews-container {
    background-color: $light-background;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      padding: 0 20px;

      &__star-icon {
        color: #f5d000;
        font-size: 22px;
        margin: 0 5px;
      }

      &__header,
      &__content,
      &__author {
        text-align: center;
      }

      &__header {
        font-size: 22px;
        margin-top: 30px;
      }

      &__content {
        font-size: 18px;
        padding: 10px 0;
      }

      &__author {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &__footer {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    h4 {
      font-size: 16px;
    }

    &__column {
      flex: 1;
      min-width: 120px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 10px;
      }

      &__list {
        list-style: none;
        padding: 0;

        li {
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
