.choose-delivery-day-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__days-section {
    padding: 0;
    margin-top: 10px;

    &__item {
      position: relative;
      list-style: none;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 30px;
      width: 300px;
      transition: 0.3s;

      &__pointer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 6px;
        background-color: blue;
      }

      p {
        margin: 0;
        padding: 0;
      }

      &:hover {
        transform: translateX(6px);
        transition: 0.3s;
      }
    }
  }

  &__next-button {
    margin: 20px 0 40px 0;
    width: 220px;
  }
}
