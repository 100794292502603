.meal-card {
  min-width: 140px;
  max-width: 320px;

  &__image {
    width: 100%;
    background-size: cover;
    background-position: center;

    &::after {
      content: '';
      padding-top: 100%;
      display: block;
    }
  }

  &__body {
    padding: 10px;

    @media (min-height: 500px) {
      h4.app-header {
        font-size: 12px;
      }
      p.app-item-description {
        font-size: 10px;
      }
    }

    h3,
    p {
      text-align: center;
    }

    h3 {
      margin-top: 8px;
    }

    &__count-wrapper {
      height: 25px;
      border-radius: 40px;
      background-color: white;
      border: 1px #c4c4c4 solid;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
        padding: 0 30px;

        @media (min-height: 500px) {
          padding: 0 10px;
        }
      }
    }

    &__controls-container {
      margin-bottom: 7px;
    }
  }
}
