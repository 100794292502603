@import '../../../variables.scss';

$page-background: #eeeeee;

.select-meals-page {
  height: 100%;

  &__layout {
    height: 100%;
    position: relative;
    background-color: $page-background;

    &__content {
      height: calc(100vh - 64px);
      background-color: $light-background;
      display: flex;
      flex-direction: column;

      &__note {
        text-align: center;
        padding: 10px 10px 0 10px;
        margin: 0;
      }

      &__container {
        flex: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-content: flex-start;
        padding: 0 10px;

        &__item {
          cursor: pointer;
          padding: 5px;
          @media (min-height: 500px) {
            width: 50%;
          }
          flex: 1;
        }
      }

      .select-meals-page__layout__content__button {
        margin-bottom: 30px;
        height: 60px;
        @media (min-width: 500px) {
          display: none;
        }
      }
    }

    &__collapse-button-container {
      box-sizing: border-box;
      z-index: 10;
      padding: 0 5px;
      height: 80px;
      background-color: white;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;

      &__info {
        @media (min-width: 400px) {
          display: none;
        }
        font-size: 14px;
      }
    }

    &__side-bar {
      height: calc(100vh - 64px);

      @media (max-width: 500px) {
        margin-top: 15vh;
        height: calc(70vh);
      }
      // overflow-y: hidden;
      background-color: $page-background;

      @media (max-width: 150px) {
        h3.app-header {
          display: none;
        }
      }

      &__content {
        height: 100%;
        display: flex;
        flex-direction: column;

        &__meals-container {
          flex: 1;
        }

        &__note {
          text-align: center;
          padding: 10px 10px 0 10px;
          margin: 0;
        }

        &__button-container {
          box-sizing: border-box;
          padding: 20px;
          background-color: $page-background;

          &__button {
            margin: 0;
            padding: 0;

            @media (max-width: 500px) {
              display: none;
            }
          }
        }
      }
    }
  }
}
