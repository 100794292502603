.common-questions {
  box-sizing: border-box;
  padding: 0 20px;

  h1 {
    width: 100%;
    text-align: center;
  }

  .common-questions__item__answer {
    font-size: 16px;
  }
}
