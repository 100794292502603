.plan-card {
  background-color: white;
  min-width: 200px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 1);
  box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 1);
  cursor: pointer;

  @media (min-width: 500px) {
    max-width: 320px;
  }

  @media (max-width: 500px) {
    display: flex;
    align-items: stretch;
  }

  &__card-image {
    @media (min-width: 500px) {
      width: 100%;
      height: 200px;
    }

    @media (max-width: 500px) {
      width: 115px;
      height: 115px;
    }
    object-fit: cover;
  }

  &__card-body {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;

    &__small-header {
      @media (min-width: 500px) {
        display: none;
      }
    }

    &__big-header {
      @media (max-width: 500px) {
        display: none;
      }
    }

    h1,
    h2,
    p {
      text-align: center;
    }

    h1 {
      font-size: 42px;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 100;
      margin-top: 10px;
    }

    @media (max-width: 500px) {
      padding: 15px;
      &__button {
        display: none;
      }

      h1,
      h2,
      p {
        text-align: left;
      }

      h1 {
        font-size: 16px;
        margin-bottom: 0;
      }
  
      h2 {
        font-size: 16px;
        margin: 0;
      }

      p {
        margin-bottom: 0;
      }
    }

    &__description-container {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media (max-width: 500px) {
        p {
          font-size: 12px;
        }
      }
    }
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(150, 150, 150, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(150, 150, 150, 1);
    box-shadow: 0px 0px 5px 0px rgba(150, 150, 150, 1);
  }
}
