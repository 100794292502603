.arabic-text {
  h1,
  h2,
  h3,
  h4,
  p,
  label {
    direction: rtl;
    font-family: 'Cairo', sans-serif;
  }
}
