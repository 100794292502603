@import 'react-alice-carousel/lib/scss/alice-carousel.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cairo&display=swap');

$light-background: #f7f7f7;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
}

.app-content {
  width: 100%;
  max-width: 1280px;
  margin: auto;
}

.app-logo {
  width: 100px;
  object-fit: contain;
}

h1.app-section-header {
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 20px;
}

p.app-section-description {
  text-align: center;
  padding: 8px 20px 20px 20px;
  font-size: 16px;
}

h3.app-header {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 30px;
  padding: 0 10px;
}

h4.app-header {
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 0;
  padding: 0 10px;
}

p.app-item-description {
  margin: 0;
  font-size: 12px;
  text-align: center;
}

.header-spacer {
  height: 64px;
  width: 100%;
}

.bullet::after {
  content: '•';
  color: #030;
  margin: 0 10px;
}

#root {
  height: 100%;
}
