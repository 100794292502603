.cart-item {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 150px) {
    &__content {
      display: none;
    }
    &__controls-container {
      display: none;
    }
  }

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  &__content {
    box-sizing: border-box;
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .app-header,
    .app-item-description {
      text-align: left;
    }
  }

  &__controls-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;

    &__add-item,
    &__remove-item {
      font-size: 18px;
      margin: 7px 0;
      cursor: pointer;
    }
    &__add-item {
      color: green;
    }
    &__remove-item {
      color: red;
    }

    p {
      margin: 0;
    }
  }
}
